<template>
  <div>
    <div class="contain1">
      <div class="flex-center title">
        网校学员
        <helpIcon style="margin-left:6px;" prompt="统计网校累计注册的用户数量，按付费人数和访问人数统计，包括已付费未付费和已拉黑的学员"></helpIcon>
      </div>
      <div class="flex-center course-detail">
        <div class="flex-center item">
          全部
          <i class="number">{{ studentNum.studentNum }}</i
          >人
        </div>
        <div class="flex-center item">
          今日新增
          <i class="number">{{ studentNum.todayStudentNum }}</i
          >人
        </div>
        <div class="flex-center item">
          已拉黑
          <i class="number">{{ studentNum.blackStudentNum }}</i
          >人
        </div>
        <div v-if="studentNum.invalidStudentNum > 0" class="flex-center item">
          已失效
          <i class="number">{{ studentNum.invalidStudentNum }}</i
          >人
          <helpIcon
            style="margin-left:6px;"
            prompt="已失效的学员指更换公众号后原来的学员数据；每个公众号的用户数据会不一样，默认均为叮叮开课公众号，绑定自己的公众号后，原来的学员会失效"
          ></helpIcon>
        </div>
      </div>
      <div class="_center">
        <div class="vt">
          <div class="treeChart">
            <treeChart :options="list" class="tree-caht"></treeChart>
          </div>
          <div class="select-time">
            <selectTimeInterval v-model="area_type_1"></selectTimeInterval>
          </div>
        </div>
        <div class="vt space-bd"></div>
        <div class="vt treeChart">
          <treeChart :lists="list2" class="tree-caht"></treeChart>
        </div>
      </div>
    </div>
    <!--学员增长趋势分析-->
    <div class="mt20 studentGrowthChart">
      <div class="flex-center header">
        <div class="title">学员增长趋势分析</div>
        <selectTimeInterval
          @input="input"
          class="selectTim"
          v-model="post.area_type"
          :option="[
            { name: '最近7天', id: 1 },
            { name: '最近30天', id: 2 }
          ]"
        ></selectTimeInterval>
        <el-date-picker
          :value="post.time"
          @input="changeTime"
          type="daterange"
          align="right"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :pickerOptions="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="line-chart">
        <lineChart :options="studentGrowthData"></lineChart>
      </div>
    </div>
    <!--学习人次占比-->
    <div class="mt20 proportionChart">
      <div class="flex-center title">
        复购/单购学员占比
        <helpIcon
          style="margin-left:6px;"
          content="统计网校不同付费类型的人数占比(不包括报名免费课和密码课的订单)<br/>复购学员：付费订单为2单及以上算复购学员；<br/>单购学员：付费订单为1单算单购学员学员"
        ></helpIcon>
      </div>
      <div class="pie-contain">
        <pieChart :pieData="list3"></pieChart>
      </div>
      <!-- <selectTimeInterval class="char-menu" v-model="area_type_2"></selectTimeInterval> -->
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import treeChart from '@/components/dataAnalysis/tree'
import lineChart from '@/components/dataAnalysis/line'
import selectTimeInterval from './selectTimeInterval'

export default {
  name: 'studentAnalysis',

  components: {
    helpIcon,
    pieChart,
    treeChart,
    lineChart,
    selectTimeInterval
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },

      area_type_1: 1,
      area_type_2: 1,

      // 网校学员 付费 访问 已拉黑
      list: {},

      // 网校学员 本月 上月 付费 访问
      list2: [],

      // 复购 单购 学员占比
      list3: [],

      // 学员增长趋势分析
      post: {
        startTime: '',
        endTime: '',
        time: '',
        area_type: 1
      },

      // 学员增长趋势
      studentGrowthData: {},

      analysis: {
        // visitNum：访问人数
        // student_pay_num：付费人数
        // student_block_num：拉黑数
      },
      lastMonth: {
        // lastMonthPayedNum：上月已付费学员
        // lastMonthVisitNum：上月访问学员
      },
      studentNum: {
        // todayStudentNum：今日注册学员
        // studentNum：全部学员
      },
      theMonth: {
        // theMonthPayedNum:本月已付费学员
        // theMonthVisitNum：本月访问学员
      }
    }
  },

  watch: {
    area_type_1: {
      handler() {
        // 基础数据API
        this.getData()
      },
      immediate: true
    },

    area_type_2: {
      handler() {
        // 获取单购/复购占比API
        this.getStudentPayData()
      },
      immediate: true
    },

    post: {
      handler() {
        // 获取学员增长数据
        this.getStudentIncrementData()
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    //监听 最近七天的点击事件
    input() {
      this.post.startTime = ''
      this.post.endTime = ''
      this.post.time = ''
    },

    // 基础数据API
    getData() {
      this.$http({
        url: '/statistics/studentBaseData',
        data: {
          area_type: this.area_type_1
        },
        callback: ({ data: { analysis, lastMonth, studentNum, theMonth } }) => {
          this.analysis = analysis
          this.lastMonth = lastMonth
          this.studentNum = studentNum
          this.theMonth = theMonth

          // 网校学员
          this.list = {
            series: {
              type: 'bar',
              barWidth: 14,
              data: [
                {
                  value: analysis.student_pay_num,
                  itemStyle: { color: '#0093F8' }
                },
                {
                  value: analysis.visitNum,
                  itemStyle: { color: '#FED74C' }
                }
              ]
            },
            xAxis: {
              data: ['付费人数', '访问人数']
            }
          }

          // 网校学员 本月 上月 付费 访问
          this.list2 = [
            ['product', '本月', '上月'],
            ['付费人次', theMonth.theMonthPayedNum, lastMonth.lastMonthPayedNum],
            ['访问人次', theMonth.theMonthVisitNum, lastMonth.lastMonthVisitNum]
          ]
        }
      })
    },

    // 获取单购/复购占比API
    getStudentPayData() {
      this.$http({
        url: '/statistics/studentSaleRate',
        data: {
          area_type: this.area_type_2
        },
        callback: ({ data: { singleNum, repeatNum } }) => {
          // 复购 单购 学员占比
          this.list3 = [
            {
              value: repeatNum,
              name: '复购学员',
              itemStyle: { color: '#FED74C' }
            },
            {
              value: singleNum,
              name: '单购学员',
              itemStyle: { color: '#0093F8' }
            }
          ]
        }
      })
    },

    // 学员增长趋势分析
    getStudentIncrementData() {
      this.$http({
        url: '/statistics/studentIncrement',
        data: this.post,
        callback: ({ data: { studentNumIncrement } }) => {
          this.studentGrowthData = {
            legend: {
              left: 0,
              data: ['新增学员', '付费学员']
            },
            xAxis: {
              data: studentNumIncrement.map(item => item[0])
            },
            series: [
              {
                name: '新增学员',
                type: 'line',
                data: studentNumIncrement.map(item => item[1]),
                lineStyle: {
                  color: '#00D966'
                },
                itemStyle: {
                  color: '#00D966',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '付费学员',
                type: 'line',
                data: studentNumIncrement.map(item => item[2]),
                lineStyle: {
                  color: '#0093F8'
                },
                itemStyle: {
                  color: '#0093F8',
                  borderWidth: 4
                },
                smooth: true
              }
            ]
          }
        }
      })
    },

    changeTime(value) {
      this.post = Object.assign({}, this.post, {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
        area_type: value ? 3 : 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.contain1 {
  padding: 20px;
  margin-top: 20px;
  white-space: nowrap;
  background: #fff;
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.course-detail {
  margin: 10px 0 30px;
  .item {
    font-size: 12px;
    margin-right: 40px;
    position: relative;
    align-items: baseline;
    color: rgba(51, 51, 51, 1);
    > i {
      margin: 0 5px;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      position: relative;
      top: 2px;
      color: rgba(51, 51, 51, 1);
    }
    .helpIcon {
      position: absolute;
      top: -4px;
      right: -11px;
    }
  }
}
.pieChart,
.treeChart {
  font-size: 0;
  position: relative;
}
.pieChart {
  // width: 516px;
  height: 246px;
  margin-bottom: 40px;
}
.space-bd {
  width: 1px;
  height: 336px;
  margin: 0 10% 0 7%;
  background: rgba(216, 216, 216, 1);
}
.treeChart {
  min-width: 400px;
  height: 306px;
}
.select-time {
  width: 316px;
  height: 30px;
  margin: 0 auto;
}
.proportionChart,
.studentGrowthChart {
  padding: 20px;
  background: #fff;
  .title {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  .pie-contain {
    height: 228px;
    position: relative;
  }
  .char-menu {
    width: 316px;
    height: 30px;
    margin: 20px auto 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
.studentGrowthChart {
  padding-bottom: 30px;
  .header {
    margin-bottom: 12px;
    .selectTim {
      width: 158px;
      margin-right: 20px;
    }
    ::v-deep .el-input,
    ::v-deep .el-range-input {
      font-size: 12px;
      line-height: 30px;
    }
    ::v-deep .el-range-separator,
    ::v-deep .el-date-editor .el-range__icon,
    ::v-deep .el-date-editor .el-range__close-icon {
      line-height: 25px;
    }
    ::v-deep .el-form-item__content {
      height: 30px;
    }
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
      width: 120px;
    }
    ::v-deep .el-date-editor {
      width: 220px;
      height: 30px;
    }
    ::v-deep .el-form-item {
      margin: 0;
    }
  }
}
.line-chart {
  height: 305px;
  position: relative;
}
</style>
